import React from "react";
import "./pages/home";
import { Dashboard, IDashboardProps } from "./pages/dashboard";
import ApplicationContext, { defaults } from "./context/ApplicationContext";

import "semantic-ui-css/semantic.min.css";

function App() {
  const dashboardProps: IDashboardProps = {
    dateTime: new Date(),
  };

  function checkTime(i: number) {
    let value = "";
    if (i < 10) {
      value = "0" + i;
    } else {
      value = "" + i;
    }
    return value;
  }

  function timeOfPayment(dateOfPayment: Date) {
    var h = dateOfPayment.getHours();
    var m = dateOfPayment.getMinutes();
    // add a zero in front of numbers<10
    return h + ":" + checkTime(m);
  }

  function createPayment(
    year: number,
    amount: number,
    acknowledged: number,
    dateOfPayment: Date
  ) {
    const displayDate =
      dateOfPayment.toLocaleDateString("en-GB") +
      " " +
      timeOfPayment(dateOfPayment);

    return displayDate;
  }

  return (
    <div>
      {/* <div>{createPayment(1, 1, 1, new Date())}</div> */}
      <ApplicationContext.Provider value={defaults}>
        <Dashboard {...dashboardProps} />
      </ApplicationContext.Provider>
    </div>
  );
}

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Row } from "react-bootstrap";
import ApplicationContext from "../../../../context/ApplicationContext";
import paymentType from "../../../../images/typeOfPayment.png";

export const Payment: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [refNumber, setRefNumber] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [showModal, setShowModal] = useState(false);

  function handlePayOnline() {
    setShowModal(true);
  }

  function viewUpdate() {
    const d1 = context.FamilyData;

    if (d1 === undefined) {
      return;
    }

    const d2 = context.FamilyData.getFamily();

    if (d2 === undefined) {
      return;
    }

    const d3 = context.FamilyData.getFamily().familyComplete;

    if (d3 === undefined) {
      return;
    }

    const d4 = context.FamilyData.getFamily().familyComplete.family;

    if (d4 === undefined) {
      return;
    }

    const data = context.FamilyData.getFamily().familyComplete.family.reference;

    if (data === undefined) {
      return;
    }

    const due = context.FamilyData.getFamily().familyComplete.totalAmountDue;

    setAmountDue(due);
    setRefNumber(data);
  }
  useEffect(() => {
    viewUpdate();
  }, [context.FamilyData]);

  function handlePay() {}

  function handleClose() {
    setShowModal(false);
  }

  const modalPayment = (): JSX.Element => {
    return (
      <div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>Make Payment</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading-center">
              <img
                src={paymentType}
                width={200}
                height={50}
                alt="payment type"
              />
            </div>
            <form
              style={{
                padding: 30,
                paddingRight: 40,
                paddingLeft: 40,
                paddingTop: 20,
              }}
            >
              <Form.Label>Name on card</Form.Label>
              <Form.Control
                type="name"
                name="nameOnCard"
                className="form-control"
              />
              <Form.Label>Card number</Form.Label>
              <Form.Control
                type="name"
                name="cardNumber"
                onChange={handlePay}
                className="form-control"
              />
              <Form.Label>Card expire date</Form.Label>
              <input
                placeholder="DateRange"
                type="date"
                className="form-control"
                name="datepicker"
                id="datepicker"
              />

              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <Form.Label>Security code</Form.Label>
              <Form.Control
                type="name"
                name="cardNumber"
                onChange={handlePay}
                className="form-control"
              />
            </form>
          </Modal.Body>
          <Modal.Footer className="modal-footer justify-content-between">
            <Button
              variant="success"
              className="modal-padding"
              onClick={handlePay}
            >
              Pay £{amountDue}
            </Button>
            <Button
              variant="primary"
              className="modal-padding"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  function referenceNumber() {
    return (
      <Card>
        <Card.Header>Reference number</Card.Header>
        <Card.Body>
          <Card.Text>
            <div style={{ textAlign: "center", fontSize: 20 }}>
              <label>{refNumber}</label>
            </div>

            {/* <div style={{ textAlign: "center", fontSize: 20, padding: 15 }}>
              To-Year payment is: £{amountDue}
            </div> */}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  function checkTime(i: number) {
    let value = "";
    if (i < 10) {
      value = "0" + i;
    } else {
      value = "" + i;
    }
    return value;
  }

  function timeOfPayment(dateOfPayment: Date) {
    var h = dateOfPayment.getHours();
    var m = dateOfPayment.getMinutes();
    // add a zero in front of numbers<10
    return h + ":" + checkTime(m);
  }

  function approvedDateTime(
    year: number,
    amount: number,
    acknowledged: number,
    dateOfPayment: Date
  ) {
    const displayDate =
      dateOfPayment.toLocaleDateString("en-GB") +
      " " +
      timeOfPayment(dateOfPayment);

    return displayDate;
  }

  function createPayment(
    year: number,
    amount: number,
    acknowledged: number,
    dateOfPayment: Date
  ) {
    return (
      <tr>
        <td>2024/2025</td>
        <td>{acknowledged === 1 ? "Yes" : "No"}</td>
        <td>{approvedDateTime(year, amount, acknowledged, dateOfPayment)}</td>
      </tr>
    );
  }

  function mainBody() {
    return (
      <div>
        <div>
          <div className="row">
            <div className="col-md-8 mb-5">
              <h2>Your Payment</h2>
              <hr />
              <Card>
                <Card.Header>Your payment history</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Year</th>
                          <th scope="col">Acknowledged</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {context.FamilyData.getFamily() === undefined ? (
                          <></>
                        ) : (
                          context.FamilyData.getFamily().familyComplete.payments.map(
                            (m, i) => {
                              return createPayment(
                                m.year,
                                m.amountPaid,
                                m.paymentStatus,
                                new Date(m.datePayment)
                              );
                            }
                          )
                        )}
                      </tbody>
                    </table>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mb-5">{referenceNumber()}</div>
          </div>
        </div>
      </div>
    );
  }

  function paypal() {
    return (
      <Card>
        <Card.Header>Paypal</Card.Header>
        <Card.Body>
          <Card.Title>Paypal</Card.Title>
          <Card.Text>Use this section if you want to pay by pay pal</Card.Text>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10 col-form-label">
              <label>something </label>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">House number</label>
            <div className="col-sm-10 col-form-label">
              <label>something here too</label>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div>
      <div>{modalPayment()}</div>
      <div>{mainBody()}</div>
    </div>
  );
};
